<template>
  <!-- @swiperight="pageBack" -->
  <v-touch
    class="container" @swiperight="pageBack"
    :swipe-options="{ direction: 'horizontal' }"
  >
    <div class="page_top" ref="PageTop">
      <div class="page_back" @click="pageBack">
        <img src="../assets/back.png" alt=""  class="page_back_icon" />
        <span class="page_back_name">{{ pageInfo.title }}</span>
      </div>
      <div class="page_service">
        <img
          src="../assets/cus.png"
          alt=""
          class="page_service_cus"
          @click="onService"
        />
        <img
          src="../assets/share.png"
          alt=""
          class="page_service_share"
          @click="onShare"
        />
      </div>
    </div>
    <div class="page_scroll_view">
      <div class="page_game" ref="PageGame">
        <div :class="pageInfo.banner ? 'page_banner' : 'x_default page_banner'">
          <p
            class="page_banner_imageview"
            :style="'height:' + availHeight + 'px'"
          >
            <img :src="pageInfo.banner + '?t=' + Date.now()" ref="loadImg" alt="" class="page_banner_image" />
          </p>
        </div>
        <Stand
          :icon="pageInfo.icon"
          :name="pageInfo.name"
          :type="pageInfo.type"
          :des="pageInfo.des"
          :tip="pageInfo.tip"
        ></Stand>

        <Multiline
          :text="pageInfo"
          :line="line"
          :more="more"
          v-on:clickevent="line = 999;more = false"
        ></Multiline>
        <van-tabs v-model="active" animated swipeable>
          <van-tab title="福利">
            <div class="page_tab">
              <Words
                title="储值回馈"
                :lists="welfare.feedback"
                :line="words1"
                class="page_tab_item"
                v-on:clickevent="words1 = 999"
              ></Words>
              
              <div v-if="fuli" class="page_null">
                暂无福利
              </div>
            </div>
          </van-tab>
          <van-tab title="礼包">
            <div class="page_tab">
              <Gift
                v-for="item in giftLists"
                :key="item.id"
                :item="item"
                class="page_gift"
                v-on:clickevent="getGift"
                v-on:clicklook="getLook"
              ></Gift>
              <div v-if="!giftLists[0]" class="page_null">
                暂无礼包
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div class="page_start" v-if="isurl">
      <div class="page_start_button" @click="clickStart(pageInfo.url)">
        开始游戏
      </div>
    </div>
    
    <!-- 登录 -->
    <van-action-sheet
      v-model="admin.login"
      title="用户登录"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="用户名"
          v-model="form.username"
          placeholder="用户名/手机号"
        />
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s1"
          @click-right-icon="clickIcon('s1')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div style="display:flex">
          <div class="my_save_btn my_login_btn" @click="clickHandle('login')">
            立即登录
          </div>
          <div class="my_save_btn my_login_btn" v-if="isOnekey" @click="clickOnekey()" >
            一键注册
          </div>
        </div>
        <div class="my_other">
          <span @click="onclick('retrieve')">找回密码</span>
          <span @click="onclick('register')">新用户注册</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 手机注册 -->
    <van-action-sheet
      v-model="admin.register"
      title="手机号注册"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c4')"
            ref="c4"
          >
            获取验证码
          </div>
        </div>
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('phoneregister')"
        >
          注册并登录
        </div>
        <div class="my_other">
          <span></span>
          <span @click="onclick('newuser')">用户名注册</span>
        </div>
      </div>
    </van-action-sheet>
    <!-- 用户名注册 -->
    <van-action-sheet
      v-model="admin.newuser"
      title="用户名注册"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="用户名"
          v-model="form.username"
          placeholder="用户名6-10位字母或数字"
        />
        <van-field
          class="my_field"
          label="密码"
          :type="ps.s4"
          @click-right-icon="clickIcon('s4')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <div class="my_saveps">
          <van-checkbox v-model="checked_xieyi" icon-size="16px">
            我已经同意<span style="color:#ff5659" @click="isAgreement()">用户协议</span>
          </van-checkbox>
          <van-checkbox v-model="checked" icon-size="16px">记住密码</van-checkbox>
        </div>
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('usernameregister')"
        >
          注册并登录
        </div>
      </div>
    </van-action-sheet>
    <!-- 找回密码 -->
    <van-action-sheet
      v-model="admin.retrieve"
      title="找回密码"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <van-field
          class="my_field"
          label="手机号"
          v-model="form.phone"
          placeholder="输入手机号"
        />
        <div class="my_code">
          <van-field
            class="my_field"
            label="验证码"
            v-model="form.code"
            placeholder="验证码"
          />
          <div
            class="my_getcode"
            @click="clickHandle('getcode', 'c3')"
            ref="c3"
          >
            获取验证码
          </div>
        </div>
        <van-field
          class="my_field"
          label="新密码"
          :type="ps.s2"
          @click-right-icon="clickIcon('s2')"
          right-icon="closed-eye"
          v-model="form.password"
          placeholder="密码6-12位字母或数字组合"
        />
        <van-field
          class="my_field"
          label="确认密码"
          :type="ps.s3"
          @click-right-icon="clickIcon('s3')"
          right-icon="closed-eye"
          v-model="form.confirmpassword"
          placeholder="密码6-12位字母或数字组合"
        />
        <div
          class="my_save_btn my_login_btn"
          @click="clickHandle('findpassword')"
        >
          重置密码
        </div>
      </div>
    </van-action-sheet>

    <!-- 用户协议 -->
    <van-action-sheet
      v-model="admin.agreement"
      title="用户协议"
      safe-area-inset-bottom
    >
      <div class="my_content">
        <p class="my_txt" v-for="item in agreementList" :key="item">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>

    <!-- 实名 -->
    <Idcard :auth="realauth" :name="realname" :idcard="realidcard" v-on:clickevent="realup"></Idcard>
  </v-touch>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { ksort } from "@/utils/ksort";
import {
  askLogin,
  askGetCode,
  askFindPass,
  askPhoneRegister,
  askUserNameRegister,
  askDetailed,
  askGiftlist,
  askAddhistory,
  askGiftCode,
  askRealName,
  askBindCard,
  askAgreement
} from "@/api";
import { Dialog, Toast } from "vant";
import { UPDATE_USER } from "@/store";
import Words from "@/components/Words.vue";
import Multiline from "@/components/Multiline.vue";
import Stand from "@/components/Stand.vue";
import Gift from "@/components/Gift.vue";
import Idcard from '@/components/Idcard.vue'
import CloseImage from "@/assets/cl.png";
import CustomerImage from '@/assets/cu.png'
import AliPayImage from '@/assets/alipay.png'
import DouImage from '@/assets/dou.png'
import WechatPayImage from '@/assets/wechatpay.png'
import { Encrypt, Decrypt, ajax2, loadPageVar, isAndroid } from "@/utils/index";
import {
  validate,
  birthDate,
} from 'chinese-idcard-checker';
export default {
  name: "Page",
  components: {
    Words,
    Multiline,
    Stand,
    Gift,
    Idcard
  },
  data() {
    return {
      realname: "",
      realidcard: "",
      realauth: false,
      stopPay: 0,
      isurl: false,
      chosenCoupon: -1,
      payvalue: "",
      payinfo: {},
      wpay: WechatPayImage,
      alipay: AliPayImage,
      doupay: DouImage,
      radio: '3',
      radio2: 'blue',
      show: false,
      cashpage: false,
      nowX: 0,
      nowY: 0,
      posX: 0,
      posY: 0,
      app_id: 0,
      screen: 0,
      playGame: false,
      more: true,
      close: CloseImage,
      customer: CustomerImage,
      active: 0,
      line: 4,
      words1: 4,
      words2: 6,
      availHeight: 0,
      pageInfo: {
        id: null,
        icon: null,
        title: null,
        name: "占位占位",
        banner: null,
        type: ["占位", "占位"],
        des: "占位占位占位占位占位占位",
        text: "占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位",
        tip: "占位"
      },
      welfare: {
        feedback: [
          "占位占位占位",
          "占位占位占位占位占位",
          "占位占位",
          "占位占位占位占位占位占位占位占位",
          "占位占位占位占位占位"
        ],
      },
      giftLists: [
        {
          id: null,
          name: "占位占位",
          des: "占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位",
          count: "19999",
          num: "0",
        },
        {
          id: null,
          name: "占位占位",
          des: "占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位",
          count: "20000",
          num: "0",
        },
        {
          id: null,
          name: "占位占位",
          des: "占位占位占位占位占位占位占位占位占位占位占位占位占位占位占位",
          count: "20000",
          num: "0",
        },
      ],
      admin: {
        login: false,
        register: false,
        newuser: false,
        retrieve: false,
        agreement: false,
      },
      form: {
        username: "", // 用户名
        password: "", // 旧密码
        confirmpassword: "", // 确认密码
        phone: "", // 手机号
        code: "", // 验证码
      },
      ps: {
        s1: "password",
        s2: "password",
        s3: "password",
        s4: "password",
        s5: "password",
        s6: "password",
        s7: "password",
      },
      fuli: false,
      handlegift: false,
      checked: true,
      checked_xieyi: true,
      sourcePath: null,
      buymenu: false,
      // 新增
      currentSelect: 0,
      cards: [
        {
          num: 500,
          txt: '5元',
          des: '充值5元=',
          total: 5,
        },
        {
          num: 1000,
          txt: '10元',
          des: '充值10元=',
          total: 10
        },
        {
          num: 5000,
          txt: '50元',
          des: '充值50元=',
          total: 50
        },
        {
          num: 10000,
          txt: '100元',
          des: '充值100元=',
          total: 100
        },
        {
          num: 20000,
          txt: '200元',
          des: '充值200元=',
          total: 200
        },
        {
          num: 50000,
          txt: '500元',
          des: '充值500元=',
          total: 500
        },
        {
          num: 100000,
          txt: '1000元',
          des: '充值1000元=',
          total: 1000
        },
        {
          num: 200000,
          txt: '2000元',
          des: '充值2000元=',
          total: 2000
        },
        {
          num: 300000,
          txt: '3000元',
          des: '充值3000元=',
          total: 3000
        }
      ],
      params: 500,
      cardTip: {},
      defaultOpen: true,
      isOnekey: false,
      currentGame: null,
      agreementList: []
    };
  },
  mounted() {
    if(judgeBigScreenIOS()) {
      this.$refs.PageTop.className = "page_top pageTopIOS"
      this.$refs.PageGame.className = "page_game pageGameIOS"
    }
    this.rlen = history.length
    this.availHeight = (document.body.offsetWidth * 96) / 100 / 2.25
    store.commit("tabbarOut");
  },
  beforeRouteEnter(to,from,next) {
    next(vm => {
      if(vm.$route.query.isonekey == 1) {
        vm.isOnekey = true
      }
      // vm.source = true
      store.commit("tabbarOut");
      vm.sourcePath = from.path
      vm.rlen = history.length
      vm.availHeight = (document.body.offsetWidth * 96) / 100 / 2.25
      var params = {
        game_id: vm.$route.query.id,
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, "qsdhajasfas123sq")
      askDetailed(params).then(res => {
        if(res.code === 1) {
          vm.screen = res.data.screen
          var data = res.data
          var explain = ""
          for (const key in data.explain) {
            if (data.explain.hasOwnProperty(key)) {
              explain += data.explain[key]
            }
          }
          if(explain == "无") {
            vm.more = false
          }
          vm.pageInfo = {
            id: data.id,
            icon: data.icon,
            title: data.name,
            name: data.name,
            banner: data.image,
            type: data.play_name,
            des: data.introduction,
            text: explain,
            url: data.game_url,
            tip: data.type_name
          }
          vm.isurl = true
          var feedback = data.feedback
          vm.welfare = {
            feedback: feedback,
          }
          vm.app_id = data.app_id
          var params = {
            app_id: data.app_id,
            token: vm.CYBoxUser ? vm.CYBoxUser.loginToken : "",
            time: Math.floor(Date.now() / 1000)
          }
          params.sign = ksort(params, "qsdhajasfas123sq")
          askGiftlist(params).then(res => {
            if(res.code === 1) {
              var data = []
              res.data.map(e => {
                data.push({
                  id: e.id,
                  name: e.name,
                  des: e.description,
                  count: e.count == 0 ? 1 : e.count,
                  num: e.num,
                  receive: e.receive
                })
              })
              vm.giftLists = data
            } else {
              vm.giftLists = []
            }
          })
          // if(vm.$route.query.source == "played") {
          //   vm.clickStart(data.game_url);
          // }

        }
      })
    })
  },
  computed: {
    ...mapGetters(["CYBoxUser", "appid", "bundle", "CYBoxIDFA", "appkey", "time"]),
  },
  methods: {
    ...mapActions([UPDATE_USER]),
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index;
    },
    onShare() {
      try {
        window.CYEventCenter.CYShare("https://www.2a3a.com/");
      } catch (error) {}
      try {
        window.webkit.messageHandlers.CYShare.postMessage("https://www.2a3a.com/");
      } catch (error) {}
    },
    getGift(e) {
      if(this.CYBoxUser) {
        this.handlegift = false
        askGiftCode({
          token: this.CYBoxUser.loginToken,
          giftTypeId: e.id,
          currentDeviceIdfa : "",
          deviceId: this.CYBoxIDFA
        }).then(res => {
          if(res.state === "1") {
            var oInput = document.createElement('input');
            oInput.value = res.message;
            document.body.appendChild(oInput);
            oInput.select();
            document.execCommand("Copy");
            oInput.style.display = 'none'
            document.body.removeChild(oInput)
            e.receive = res.message
            Dialog.alert({
              title: res.message,
              message: "兑换码已自动复制, 游戏中直接粘贴即可",
              theme: "round-button",
            }).then(() => {});
          }
        })
      } else {
        this.handlegift = true
        this.admin.login = true;
      }
    },
    getrestGift() {
      var params = {
        app_id: this.app_id,
        token: this.CYBoxUser ? this.CYBoxUser.loginToken : "",
        time: Math.floor(Date.now() / 1000)
      }
      params.sign = ksort(params, "qsdhajasfas123sq")
      askGiftlist(params).then(res => {
        if(res.code === 1) {
          var data = []
          res.data.map(e => {
            data.push({
              id: e.id,
              name: e.name,
              des: e.description,
              count: e.count == 0 ? 1 : e.count,
              num: e.num,
              receive: e.receive
            })
          })
          this.giftLists = data
        } else {
          this.giftLists = []
        }
      })
    },
    getLook(e) {
      var oInput = document.createElement('input');
      oInput.value = e.receive;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.style.display = 'none'
      document.body.removeChild(oInput)
      Dialog.alert({
        title: e.receive,
        message: "兑换码已自动复制, 游戏中直接粘贴即可",
        theme: "round-button",
      }).then(() => {});
    },
    onService() {
      store.commit("incrementOut");
      store.commit("tabbarIn");
      this.$router.push("/customer");
    },
    pageBack() {
      // if(this.sourcePath != "/played") {
      store.commit("tabbarIn");
      // }
      store.commit("incrementOut");
      // Toast(this.rlen)
      // setTimeout(() => {
      //   Toast(history.length)
      // }, 3000)
      // setTimeout(() => {
      this.$router.go(-1)
      // }, 8000)
      // this.$router.go(this.rlen - history.length - 1);
      // 重置
      // Object.assign(this.$data, this.$options.data())
    },
    clickStart(url) {
      if (this.CYBoxUser) {
        var realparam = {
          appid: this.appid,
          token: this.CYBoxUser.loginToken,
          currentDeviceIdfa: "",
          deviceId: this.CYBoxUser.deviceId
        }
        askRealName(realparam).then(realres => {
          if(realres.state == 1) {
            if(realres.message.card == 0) {
              this.realauth = true
            } else {
              try {
                if(this.screen == 1) {
                  if(
                    navigator.userAgent.indexOf('iPhone OS 16') > -1 || 
                    navigator.userAgent.indexOf('iPhone OS 17') > -1 ||
                    navigator.userAgent.indexOf('iPhone OS 18') > -1 ||
                    navigator.userAgent.indexOf('iPhone OS 19') > -1
                  ) {
                    window.webkit.messageHandlers.CYDirection.postMessage("vertical")
                  } else {
                    window.webkit.messageHandlers.CYDirection.postMessage("horizontal")
                  }
                } else {
                  window.webkit.messageHandlers.CYDirection.postMessage("vertical")
                }
              } catch (error) {}
              var par = {
                token: this.CYBoxUser.loginToken,
                game_id: this.$route.query.id,
                time: Math.floor(Date.now() / 1000)
              }
              par.sign = ksort(par, "qsdhajasfas123sq")
              askAddhistory(par).then(res => {
              })
              url = url.replace('/BoxH5/', '/2022BoxH5/')
              
              store.commit("incrementOut");
              // this.page = false
              
              if(this.CYBoxUser.deviceId) {
                window.location.href = url + "?deviceid=" + this.CYBoxUser.deviceId + "&name=" + this.CYBoxUser.loginName + "&token=" + this.CYBoxUser.loginToken + "&userid=" + this.CYBoxUser.userID + "&type=" + this.CYBoxUser.resultType + "&time=" + this.CYBoxUser.registerTime + "&sign=" + this.CYBoxUser.sign + "&bundle=" + this.bundle
              }

            }
          }
        })
      } else {
        this.admin.login = true;
      }
    },
    openClose() {
      this.defaultOpen = false
    },
    drawerClose() {
      this.defaultOpen = true
    },
    clickClose() {
      Dialog.confirm({
        title: '是否退出游戏',
      }).then(() => {
        this.playGame = false;
        document.body.style.overflow = "auto";
        try {
          window.webkit.messageHandlers.CYDirection.postMessage("vertical");
        } catch (error) {}
        try {
          window.CYEventCenter.CYDirection("0");
        } catch (error) {}
      }).catch(() => {
        // on cancel
      });
    },
    clickRef() {
      this.currentGame = null
      this.clickStart(this.pageInfo.url)
    },
    realup(vname, vidcard) {
      var params = {
        token: this.CYBoxUser.loginToken,
        name: vname,
        number: vidcard,
        currentDeviceIdfa: "",
        deviceId: this.CYBoxIDFA,
      };
      params.sign = ksort(params, this.appkey);
      if(validate(vidcard)) {
        var ctime = Math.floor(Date.now() / 1000)
        var adulttime = (birthDate(vidcard) - 0) / 1000 + 31536000 * 18
        if(ctime > adulttime) {
          if(vname.trim().length < 2) {
            Toast("请输入姓名");
          } else {
            askBindCard(params).then((res) => {
              if (res.state === "1") {
                Toast.success("实名认证成功");
                this.realauth = false
                this.clickStart(this.pageInfo.url)
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          }
        } else {
          Dialog.alert({
            message: '检测到您的年龄为未成年, 禁止登录游戏',
            theme: "round-button",
          }).then(() => {});
        }
      } else {
        Toast("请输入正确的身份证号");
      }
    },
    clickHandle(handle, transmit) {
      switch (handle) {
        case "login":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              username: this.form.username,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              eventType: 1,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askLogin(params).then((res) => {
              if (res.state === "1") {
                //  ----------------------------------
                var temp = res.message;
                temp.password = Encrypt(this.form.password);
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if(this.checked) {
                  store.commit("CYBoxLists", temp)
                }
                this.admin.login = false;
                if(this.handlegift) {
                  this.getrestGift()
                } else {
                  this.clickStart(this.pageInfo.url);
                }
                this.form = this.$options.data().form;

              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "getcode":
          var params = {
            appid: this.appid,
            phonenum: this.form.phone,
            time: Math.floor(Date.now() / 1000),
            currentAppBuild: this.bundle,
            currentDeviceIdfa: "",
            deviceId: this.CYBoxIDFA,
          };
          params.sign = ksort(params, this.appkey);
          askGetCode(params).then((res) => {
            if (res.state === "1") {
              var i = Number(res.message);
              Toast("验证码已发送, 请查收");
              let timer = setInterval(() => {
                if (i == 0) {
                  this.$refs[transmit].style.cssText +=
                    "background:linear-gradient(90deg, #ff5659 35%, #ff0103 100%);pointer-events: auto;";
                  this.$refs[transmit].innerHTML = "获取验证码";
                  clearInterval(timer);
                } else {
                  this.$refs[transmit].style.cssText +=
                    "background:#666;pointer-events: none;";
                  this.$refs[transmit].innerHTML = i;
                }
                i--;
              }, 1000);
            } else {
              Dialog.alert({
                message: res.message,
                theme: "round-button",
              }).then(() => {});
            }
          });
          break;
        case "findpassword":
          if (this.form.password === this.form.confirmpassword) {
            var params = {
              appid: this.appid,
              phonenum: this.form.phone,
              code: this.form.code,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            params.sign = ksort(params, this.appkey);
            askFindPass(params).then((res) => {
              if (res.state === "1") {
                Toast.success("密码已重置");
                this.admin.retrieve = false;
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "两次密码输入不一致",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "phoneregister":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              phonenum: this.form.phone,
              code: this.form.code,
              time: Math.floor(Date.now() / 1000),
              eventType: 2,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askPhoneRegister(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message
                temp.password = Encrypt(res.message.password)
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if(this.checked) {
                  store.commit("CYBoxLists", temp)
                }
                this.admin.register = false;
                this.admin.login = false;
                Toast("登录成功");
                if(this.handlegift) {
                  this.getrestGift()
                } else {
                  this.clickStart(this.pageInfo.url);
                }
                Dialog.alert({
                  title: '您的初始密码, 请妥善保管',
                  message: Decrypt(res.message.password),
                  theme: "round-button",
                }).then(() => {});
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        case "usernameregister":
          if(this.checked_xieyi) {
            var params = {
              appid: this.appid,
              username: this.form.username,
              password: this.form.password,
              time: Math.floor(Date.now() / 1000),
              eventType: 2,
              currentAppBuild: this.bundle,
              currentDeviceIdfa: "",
              deviceId: this.CYBoxIDFA,
            };
            var isKefu = localStorage.getItem('isKefu')
            if(isKefu) params.isKefu = localStorage.getItem('isKefu')
            params.sign = ksort(params, this.appkey);
            askUserNameRegister(params).then((res) => {
              if (res.state === "1") {
                var temp = res.message;
                temp.password = Encrypt(this.form.password);
                this.UPDATE_USER(temp);
                store.commit("loginIn");
                if(this.checked) {
                  store.commit("CYBoxLists", temp)
                }
                this.admin.register = false;
                this.admin.login = false;
                this.admin.newuser = false;
                Toast("登录成功");
                if(this.handlegift) {
                  this.getrestGift()
                } else {
                  this.clickStart(this.pageInfo.url);
                }
                this.form = this.$options.data().form;
              } else {
                Dialog.alert({
                  message: res.message,
                  theme: "round-button",
                }).then(() => {});
              }
            });
          } else {
            Dialog.alert({
              message: "需要同意用户协议",
              theme: "round-button",
            }).then(() => {});
          }
          break;
        default:
          break;
      }
    },
    clickOnekey() {
      var params = {
        appid: this.appid,
        eventType: 2,
        currentAppBuild: loadPageVar("bundle"),
        currentDeviceIdfa: "",
        deviceId: this.CYBoxIDFA
      }
      params.sign = ksort(params, this.appkey)
      ajax2({
        method: "POST",
        url: '/Login/getName',
        data: params,
        success: res => {
          if (res.state === "1") {
            var temp = res.message;
            this.UPDATE_USER(temp);
            store.commit("loginIn");
            if(this.checked) {
              store.commit("CYBoxLists", temp)
            }
            this.admin.login = false;
            if(this.handlegift) {
              this.getrestGift()
            } else {
              this.clickStart(this.pageInfo.url);
            }
            this.form = this.$options.data().form;
          } else {
            Dialog.alert({
              message: res.message,
              theme: "round-button",
            }).then(() => {});
          }
        }
      })
    },
    onclick(id) {
      this.admin[id] = true;
    },
    clickIcon(e) {
      this.ps[e] = "text";
    },
    isAgreement() {
      this.admin.agreement = true
      askAgreement().then((res) => {
        this.agreementList = res;
      });
    }
  },
};
</script>
<style scoped>
.the-game-in-enter-active,
.the-game-in-leave-active,
.the-game-out-enter-active,
.the-game-out-leave-active {
  will-change: transform;
  position: absolute;
  transition: all 500ms;
}

.the-game-in-enter {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.the-game-in-leave-active {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
.the-game-out-enter {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
.the-game-out-leave-active {
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}
</style>
<style lang="stylus" scoped>
.container {
  height 100vh
}
@import '../components/common.styl';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 202px;
  height: 240px;
  background-color: #fff;
  border-radius 5px;
  overflow: hidden;
}

.page_pay {
  width: 190px;
  height: 48px;
  border-radius 24px;
  background:linear-gradient(90deg,#ff5555 50%,#fe0000 100%);
  color #fff
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px auto 0;
  font-size 18px
}

.page_scroll_view {
  padding-bottom: 5px;
  margin-bottom: 60px;
}

.page_game {
  background: $themeWhite;
  margin: 0 auto 4px;
  margin-top: 44px
}

.page_top {
  margin: 0 auto;
  padding: 4px 2% 4px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: $themeWhite;
  min-height: 40px
}


.page_banner {
  width: 100%;
  text-align: center;
}

.page_banner_imageview {
  margin: 0 auto 10px;
  width: $viewWidth;
}

.page_banner_image {
  width: 100%;
  height: 100%;
  border-radius 5px
  background #f4f4f4
}

.page_back {
  display: flex;
  align-items: center;
}

.page_back_icon {
  height: 12px;
  width: 8px;
}

.page_back_name {
  font-size: 16px;
  font-weight: 600;
  padding-left: 6px;
}

.page_service {
  display: flex;
  align-items: center;
}

.page_service_cus {
  height: 28px;
  width: 28px;
}

.page_service_share {
  height: 20px;
  width: 20px;
  margin-left: 18px;
}

.page_start {
  width: 100%;
  height: 64px;
  background: $themeWhite;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.page_start_button {
  width: 294px;
  height: 38px;
  line-height: 38px;
  color: #ffffff;
  background: linear-gradient(90deg, $themeMin 35%, $themeMax 100%);
  text-align: center;
  border-radius: 19px;
  font-size: 18px;
  margin: 6px auto;
  letter-spacing 1px
}

.page_tab {
  background: $themeBackground;
  padding-top: 1px;
  border: 1px solid rgba(0, 0, 0, 0);
}

.page_tab_item {
  margin-top: 6px;
}

.page_gift {
  margin: 5px auto;
}
.page_null {
  background: #fff;
  text-align: center;
  height: 50px
  line-height: 50px;
  color: #666;
  width: 96%;
  margin 6px auto
  border-radius 5px
  font-size 14px
}

.game_content {
  width: 100%;
  height: 100%;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000;
}

.game_core {
  width: 100%;
  height: 100%;
}

.game_coreH {
  padding-bottom: 0;
  padding-left: calc(constant(safe-area-inset-left) - 10px);
  padding-left: calc(env(safe-area-inset-left) - 10px);
}

.game_coreS {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
.game_top_close {
  z-index: 1000;
  position: absolute;
  right: 20px;
  top: calc(10px + constant(safe-area-inset-top));
  top: calc(10px + env(safe-area-inset-top));
  width: 80px;
  display: flex
  align-items center
  justify-content: space-around;
  height 28px
  border-radius 18px
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255,255,255,0.3);
  padding 0 4px
}

.game_new_customer {
  height 100%
  width 100%
  position: absolute;
}
.ball {
  position: fixed;
  right: -.4em;
  top: 30%;
  width: 42px;
  height: 42px;
  z-index: 100;
  background-clip: padding-box;
  text-decoration: none;
}
.ball.ball_right .game_new_customer {
  right: 0;
  bottom: 0
}
.ball.ball_left .game_new_customer {
  left: 0;
  bottom: 0
}
.open_close {
  position: absolute;
  right: 13px;
  top: 0;
  width: 108px;
  height: 37.5px;
  background url("../assets/g.png") center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
}
.open_close_view {
  height 100%
  width: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.open_tip {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.open_tip_img {
  height 70%
}
.open_close_l {
  height 48%
  position: relative;
  left: 1px;
}
.game_line {
  width 1px
  height 14px
  background rgba(255,255,255,0.3)
}
.game_customer, .game_close {
  width: 24px;
  height: 24px;
}
.cash_view {
  margin 0 auto;
  width 330px
  position relative
  color #fff
}
.cash_receive {
  width 100%;
}
.cash_receive_gray {
  color #666 !important
}

.cash_num {
  position absolute
  top 8px
  left 16px
  font-size 32px
}
.cash_text {
  position absolute
  top 16px
  left 104px
  color #ff0103
  font-size 14px
}
.cash_text_gray {
  color #666
}
.cash_time {
  position absolute
  top 58px
  left 16px
  font-size 12px
}
.cash_name {
  position absolute
  top 72px
  left 16px
  font-size 12px
}
.cash_get {
  font-size 14px
  color #fff
  position absolute
  top -4px
  right 44px
  height 100%
  display flex
  align-items: center;
}

.van-overlay {
  z-index: 100 !important;
}
</style>

<style lang="stylus" scoped>
@import '../components/common.styl';
.my_payview
  width $viewWidth
  background #fff
  margin 0 auto 10px
  border-radius 2px
.my_v
  color $themeMax
  text-align: center;
  padding-top 30px
  padding-bottom 30px
.my_symbol
  font-size 20px
.my_pay
  font-size 36px
.my_pay_txt
  font-size 16px
  text-align center
  padding 10px
.my_pay_center
  padding 10px 0 10px
</style>

<style lang="stylus" scoped>
.card_tip
  width 96%
  margin: 0 auto 5px;
  padding 4px 8px;
  border-radius: 4px;
  font-size 14px
  background #fff
  color #f55
.card_tip_
  width 96%
  margin: 0 auto 0;
  padding 6px 8px 0;
  font-size 14px
  color #f55
.card_top
  background #fff
  border-radius 4px
  margin-bottom 10px
  width 96%
  margin 0 auto 5px;
  color #5d5a5a
  display flex
  flex-wrap: wrap;
  align-items: center;
  padding: 6px 0;
  justify-content: space-evenly;
.card_fuhao
  font-size 20px
.card_je
  font-size 34px
.card_center
  border-radius 4px
  background #fff
  width 96%
  margin 0 auto
.card_fs
  font-size 18px
  text-align center
  padding-top 16px
  padding-bottom 28px
.card_select
  margin 0 auto
  width 80%

.card_card {
  font-size: 0;
  height: 130px;
  margin-bottom: 10px;
}

.card_title {
  font-size: 16px;
  margin-bottom: 6px;
}

.card_txt {
  font-size: 12px;
  text-align: left;
  width: 96%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.card_text {
  margin-bottom: 4px;
  letter-spacing: 2px;
  line-height: 18px;
}

.card_i
  height 72px
  width 30%
  background #f6f6f6
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius 2px
  margin: 4px 0;
.card_count
  font-size 16px
  margin-bottom: 4px;
.card_des
  font-size 9px 
.page_pay
  width: 188px
  height: 46px;
  border-radius 23px;
  font-size 18px
  background: #20a0ff;
  background:linear-gradient(90deg,#ff5555 50%,#ff0000 100%);
  color #fff
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 28px auto 0;
</style>
<style scoped>
.cy_animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.cy_animated.cy_infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}
@media(print),
(prefers-reduced-motion) {
  .cy_animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    transition: none !important
  }
}

.cy_heartBeat {
  -webkit-animation-name: cy_heartBeat;
  animation-name: cy_heartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out
}

@-webkit-keyframes cy_heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes cy_heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3)
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
}
</style>